// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: relative;
}

.title {
  font-size: 2rem;
  margin-bottom: 1rem;
  z-index: 1;
  color: #ffffff;
}

.start-button {
  background-color: #ffffff;
  color: #000;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 12px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
  z-index: 1;
}

.start-button:hover {
  background-color: #f1f1f1;
  box-shadow: none;
}

.overlay-svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100vw;
  max-height: 100vh;
  opacity: 0.5;
}

`, "",{"version":3,"sources":["webpack://./src/components/HomeComponent.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,UAAU;EACV,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,kBAAkB;EAClB,YAAY;EACZ,eAAe;EACf,eAAe;EACf,mBAAmB;EACnB,0CAA0C;EAC1C,oBAAoB;EACpB,UAAU;AACZ;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,gBAAgB;EAChB,iBAAiB;EACjB,YAAY;AACd","sourcesContent":[".home-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n  position: relative;\n}\n\n.title {\n  font-size: 2rem;\n  margin-bottom: 1rem;\n  z-index: 1;\n  color: #ffffff;\n}\n\n.start-button {\n  background-color: #ffffff;\n  color: #000;\n  padding: 10px 20px;\n  border: none;\n  cursor: pointer;\n  font-size: 16px;\n  border-radius: 12px;\n  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);\n  transition: all 0.3s;\n  z-index: 1;\n}\n\n.start-button:hover {\n  background-color: #f1f1f1;\n  box-shadow: none;\n}\n\n.overlay-svg {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  max-width: 100vw;\n  max-height: 100vh;\n  opacity: 0.5;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.character-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.character-card {
  width: 30%;
  margin-bottom: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  cursor: pointer; 
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.character-card:hover {
  box-shadow: none;
  transform: scale(0.99);
}

.character-card img {
  width: 100%;
  height: auto;
}

.logout-button {
  background-color: #ffffff;
  color: #000;
  padding: 10px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 20%;
  border-radius: 12px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  transition: all 0.3s;
}

.logout-button:hover {
  background-color: #f1f1f1;
  box-shadow: none;
}

.button-container {
  text-align: center;
}

.white-text {
  color: white;
  padding-left: 2%;
}`, "",{"version":3,"sources":["webpack://./src/components/CharacterSelect.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,6BAA6B;AAC/B;;AAEA;EACE,UAAU;EACV,mBAAmB;EACnB,0CAA0C;EAC1C,kBAAkB;EAClB,gBAAgB;EAChB,sBAAsB;EACtB,eAAe;EACf,qDAAqD;AACvD;;AAEA;EACE,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,kBAAkB;EAClB,aAAa;EACb,YAAY;EACZ,eAAe;EACf,UAAU;EACV,mBAAmB;EACnB,0CAA0C;EAC1C,eAAe;EACf,oBAAoB;AACtB;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":[".character-container {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-around;\n}\n\n.character-card {\n  width: 30%;\n  margin-bottom: 20px;\n  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);\n  border-radius: 8px;\n  overflow: hidden;\n  background-color: #fff;\n  cursor: pointer; \n  transition: box-shadow 0.3s ease, transform 0.3s ease;\n}\n\n.character-card:hover {\n  box-shadow: none;\n  transform: scale(0.99);\n}\n\n.character-card img {\n  width: 100%;\n  height: auto;\n}\n\n.logout-button {\n  background-color: #ffffff;\n  color: #000;\n  padding: 10px 20px;\n  margin: 8px 0;\n  border: none;\n  cursor: pointer;\n  width: 20%;\n  border-radius: 12px;\n  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);\n  font-size: 16px;\n  transition: all 0.3s;\n}\n\n.logout-button:hover {\n  background-color: #f1f1f1;\n  box-shadow: none;\n}\n\n.button-container {\n  text-align: center;\n}\n\n.white-text {\n  color: white;\n  padding-left: 2%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

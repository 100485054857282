// utils/sessionCheck.js
import axios from 'axios';

const sessionCheck = async (dispatch, navigate) => {
  try {
    await axios.get('http://localhost:8000/api/session-check/', { withCredentials: true });
  } catch (error) {
    if (error.response && error.response.status === 403) {
      dispatch({ type: 'LOGOUT' });
      navigate('/'); 
    }
  }
};

export default sessionCheck;

import React, { useState, useContext, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../contexts/AuthContext';
import { CharacterContext } from '../contexts/CharacterContext';
import sessionCheck from '../utils/sessionCheck';
import LoadingSpinner from './LoadingSpinner';
import './QuestionPage.css';

const QuestionPage = () => {
    const { isAuthenticated } = useContext(AuthContext);
    const navigate = useNavigate();
    const { characterId } = useParams();
    const { selectedCharacter, setSelectedCharacter } = useContext(CharacterContext);
    const contextValue = useContext(AuthContext);
    const [question, setQuestion] = useState('');
    const [response, setResponse] = useState(null);
    const [lastQuestion, setLastQuestion] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const cancelTokenSource = useRef(axios.CancelToken.source());

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/login');
        }
    }, [isAuthenticated, navigate]);

    useEffect(() => {
        sessionCheck(contextValue.dispatch, navigate);
    }, [contextValue.dispatch, navigate]);

    useEffect(() => {
        if (!selectedCharacter) {
            const characterFromLocalStorage = JSON.parse(localStorage.getItem('selectedCharacter'));
            setSelectedCharacter(characterFromLocalStorage);
        }
    }, [selectedCharacter, setSelectedCharacter]);

    useEffect(() => {
        return () => {
            cancelTokenSource.current.cancel('Component got unmounted');
        };
    }, []);

    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    const handleQuestionSubmit = async () => {
        setResponse(null);
        setErrorMessage('');

        setIsLoading(true);
        cancelTokenSource.current = axios.CancelToken.source();

        try {
            if (question.trim() === '') {
                setIsLoading(false);
                return;
            }
            setLastQuestion(question);
            setQuestion(''); // テキストフィールドをクリア

            const csrfResponse = await axios.get(`${apiBaseUrl}/api/csrf/`, { withCredentials: true });
            const csrfToken = csrfResponse.data.csrfToken;

            const serverResponse = await axios.post(
                `${apiBaseUrl}/api/ask/${characterId}/`, 
                { question: question }, 
                { 
                    headers: { 'X-CSRFToken': csrfToken },
                    withCredentials: true,
                    cancelToken: cancelTokenSource.current.token,
                    timeout: 20000
                    
                }
            );

            if (serverResponse.data && serverResponse.data.answer) {
                setResponse(serverResponse.data);
            } else {
                console.error('Unexpected response format:', serverResponse);
                setErrorMessage('エラーが発生しました。再度お試しください。');
            }
        } catch (error) {
            if (!axios.isCancel(error)) {
                if (error.code === 'ECONNABORTED') {
                    setErrorMessage('質問に対する回答が時間内に返ってこなかったので、もう一度やり直すか、しばらく時間が経ってから試してみてね。');
                } else {
                    setErrorMessage(error.response?.data?.error || 'エラーが発生しました。再度お試しください。');
                }
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="question-page">
            {isLoading && <LoadingSpinner />}
            <button className="return-button" onClick={() => navigate('/characterselect')}>戻る</button>
            <div className="white-background">
                <div className="question-page-character-container">
                    {response && (
                        <div className="response-bubble-container">
                            <img src={response.speech_bubble} alt="Speech Bubble" className="response-speech-bubble"/>
                            <p className="response-text">{response.answer}</p>
                        </div>
                    )}
                    <img 
                        src={response ? response.character_image : selectedCharacter.image_url}
                        alt={selectedCharacter ? selectedCharacter.name : 'Character'}
                        className="selected-character-image"
                    />
                    {response && response.sound_effect && (
                        <img src={response.sound_effect} alt="Sound Effect" className="sound-effect" />
                    )}
                </div>
                {lastQuestion && (
                    <div className="user-question">
                        <p>{lastQuestion}</p>
                    </div>
                )}
                <div className="response-background" style={{ backgroundImage: response ? `url(${response.background})` : 'none' }}></div>
                {errorMessage && <div className="error-message">{errorMessage}</div>}
                <div className="centered-container">
                    <textarea 
                        className="styled-textarea" 
                        value={question} 
                        onChange={(e) => setQuestion(e.target.value)}
                        placeholder="質問文を入力…" 
                    />
                    <button className="styled-button" onClick={handleQuestionSubmit}>質問する</button>
                </div>
            </div>
        </div>
    );    
};

export default QuestionPage;
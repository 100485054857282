import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import './HomeComponent.css';



function HomeComponent() {
  const staticUrl = process.env.REACT_APP_STATIC_URL || 'http://localhost:8000/static';
  const LiverSvgUrl = `${staticUrl}/Liver.svg`;
  const navigate = useNavigate();
  const { isAuthenticated } = useContext(AuthContext);

  const handleStartClick = () => {
    if (isAuthenticated) {
      navigate('/characterselect');
    } else {
      navigate('/login');
    }
  };

  return (
    <div className="home-container">
      <h1 className="title">Mangabot</h1>
      <button className="start-button" onClick={handleStartClick}>はじめる</button>
      <img src={LiverSvgUrl} alt="Liver Image" className="overlay-svg" />
    </div>
  );
}

export default HomeComponent;

import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { CharacterContext } from '../contexts/CharacterContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './CharacterSelect.css';
import sessionCheck from '../utils/sessionCheck';

const CharacterSelect = () => {
  const { isAuthenticated } = useContext(AuthContext); // 認証状態を取得
  const [characters, setCharacters] = useState([]);
  const contextValue = useContext(AuthContext);
  const { selectedCharacter, setSelectedCharacter } = useContext(CharacterContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login'); // ログインしていなければログインページへリダイレクト
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    sessionCheck(contextValue.dispatch, navigate);
  }, []);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    if (contextValue && contextValue.isAuthenticated) {
      axios.get(`${apiBaseUrl}/api/characters/`)
        .then((response) => {
          setCharacters(response.data);
        })
        .catch((error) => {
          console.error('Error fetching data: ', error);
        });
    }
  }, [contextValue]);

  if (!contextValue || !contextValue.isAuthenticated) {
    return <div>Loading...</div>;
  }

  const { user, dispatch } = contextValue;

  const handleLogout = async () => {
    try {
      await axios.post(`${apiBaseUrl}/api/logout/`);
      dispatch({ type: 'LOGOUT' });
      navigate('/'); 
    } catch (error) {
      console.error('Error during logout:', error.response ? error.response.data : error.message);
    }
  };

  const handleCharacterClick = (character) => {
      setSelectedCharacter(character);
      localStorage.setItem('selectedCharacter', JSON.stringify(character));
      navigate(`/question-page/${character.id}`);
  };

  return (
    <div>
      <h1 className="white-text">{user ? user.username : 'Loading...'}</h1>
      <div className="character-container">
        {
          characters.map(character => (
            <div key={character.id} className="character-card" onClick={() => handleCharacterClick(character)}>
              <h2>{character.name}</h2>
              <p>{character.personality_type}</p>
              <img src={character.image_url} alt={character.name} />
            </div>
          ))
        }
      </div>
      <div className="button-container">
        <button className="logout-button" onClick={handleLogout}>ログアウト</button>
      </div>
    </div>
  );
};

export default CharacterSelect;

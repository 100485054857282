import React, { useState, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../contexts/AuthContext';
import { useNavigate, Link } from 'react-router-dom';
import './LoginComponent.css';
import '../App.css';

const LoginComponent = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { dispatch } = useContext(AuthContext);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const getCSRFToken = async () => {
    const response = await axios.get(`${apiBaseUrl}/api/csrf/`, { withCredentials: true });
    return response.data.csrfToken;
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const csrfToken = await getCSRFToken();
      const response = await axios.post(`${apiBaseUrl}/api/login/`, {
        username,
        password
      }, {
        headers: {
          'X-CSRFToken': csrfToken
        },
        withCredentials: true
      });

      if (response.status === 200) {
        alert("ログインに成功しました。");
        dispatch({ type: 'LOGIN', payload: { username: response.data.username } });
        navigate('/characterselect');
      }
    } catch (error) {
      alert("ログインに失敗しました。");
      console.error('Error during login:', error.response ? error.response.data : error.message);
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleLogin} className="form-group">
        <input type="text" className="common-input" value={username} onChange={e => setUsername(e.target.value)} placeholder="Name" />
        <input type="password" className="common-input" value={password} onChange={e => setPassword(e.target.value)} placeholder="Password" />
        <button type="submit" className="common-button login-button">ログイン</button>
      </form>
      <Link to="/signup">新規登録はこちら</Link>
    </div>
  );
};

export default LoginComponent;

import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import CharacterSelect from './components/CharacterSelect';
import QuestionPage from './components/QuestionPage';
import LoginComponent from './components/LoginComponent';
import SignupComponent from './components/SignupComponent';
import HomeComponent from './components/HomeComponent';
import { AuthProvider } from './contexts/AuthContext';
import { CharacterProvider } from './contexts/CharacterContext';
import './App.css';

// 不正なURLへのアクセスを処理するコンポーネント
function HandleInvalidPath({ setLastValidPath, lastValidPath }) {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // 有効なURLのリスト
    const validPaths = ['/', '/characterselect', '/login', '/signup'];
    const isQuestionPage = /^\/question-page\/\d+$/.test(location.pathname); // /question-page/:characterId 形式のパスチェック

    if (validPaths.includes(location.pathname) || isQuestionPage) {
      setLastValidPath(location.pathname);
    } else {
      navigate(lastValidPath);
    }
  }, [location, navigate, setLastValidPath, lastValidPath]);

  return null;
}

function App() {
  const staticUrl = process.env.REACT_APP_STATIC_URL || 'http://localhost:8000/static';
  const [lastValidPath, setLastValidPath] = useState('/');

  const appStyle = {
    minHeight: '100vh',
    overflow: 'hidden',
    background: `linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.5)), url('${staticUrl}/stage.png')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  return (
    <AuthProvider>
      <CharacterProvider>
        <Router>
          <HandleInvalidPath setLastValidPath={setLastValidPath} lastValidPath={lastValidPath} />
          <div className="App" style={appStyle}>
            <Routes>
              <Route path="/characterselect" element={<CharacterSelect />} />
              <Route path="/question-page/:characterId" element={<QuestionPage />} />
              <Route path="/login" element={<LoginComponent />} />
              <Route path="/signup" element={<SignupComponent />} />
              <Route path="/" element={<HomeComponent />} />
            </Routes>
          </div>
        </Router>
      </CharacterProvider>
    </AuthProvider>
  );
}

export default App;

import React, { createContext, useState } from 'react';

export const CharacterContext = createContext();

export const CharacterProvider = ({ children }) => {
  const initialCharacter = JSON.parse(localStorage.getItem('selectedCharacter'));
  const [selectedCharacter, setSelectedCharacter] = useState(initialCharacter);

  return (
    <CharacterContext.Provider value={{ selectedCharacter, setSelectedCharacter }}>
      {children}
    </CharacterContext.Provider>
  );
};

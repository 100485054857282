import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import './SignupComponent.css';
import '../App.css';

const SignupComponent = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { dispatch } = useContext(AuthContext);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${apiBaseUrl}/api/users/`, {
        username,
        email,
        password
      });

      if (response.status === 201) {
        alert("ユーザー登録に成功しました。");
        dispatch({ type: 'LOGIN', payload: { username } });
        navigate('/characterselect');
      }
    } catch (error) {
      alert("ユーザー登録に失敗しました。");
      console.error('Error during user creation:', error.response ? error.response.data : error.message);
    }
  };

  return (
    <div className="signup-container">
      <form onSubmit={handleSubmit} className="form-group">
        <input type="text" className="common-input" value={username} onChange={e => setUsername(e.target.value)} placeholder="Name" />
        <input type="email" className="common-input" value={email} onChange={e => setEmail(e.target.value)} placeholder="Email" />
        <input type="password" className="common-input" value={password} onChange={e => setPassword(e.target.value)} placeholder="Password" />
        <button type="submit" className="common-button signup-button">登録</button>
      </form>
      <Link to="/login">ログインはこちら</Link>
    </div>
  );
};

export default SignupComponent;
